<template src="./completeinteracmoneris.html"></template>
<style scoped src="./completeinteracmoneris.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components : {
      Loading, modal, pagination
    },
    data () {
        return {
            searchBy1:'',
            searchBy2:'allTime',
            searchValue:null,
            loaderVisible:false,
            transactionSummary: [],
            errorMessages: applicationConstants.formsMessages,
            columns :['DateTime','TransactionId','ReferenceNumber','SenderName','SenderEmail','RecipientName','Amount','Action'],
            options :{
                headings : {
                    TransactionId: 'Transaction ID',
                    ReferenceNumber:'Reference Number',
                    SenderName: 'Sender Name' ,
                    SenderEmail:'Sender Email',
                    RecipientName : 'Recipient Name',
                },
                uniqueKey : 'ReferenceNumber'
            },
            Utils : Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    methods : {
        async showAllTransaction() {
            try {
                this.loaderVisible = true
                this.$v.$reset()
                this.searchValue = null
                this.searchBy2 = 'allTime'
                this.searchBy1 = ''
                let res = await TransactionService.methods.GetTransactionSummary({
                    FundingSource : '2',
                    TransactionStatus : '35',
                    TransactionType : '2'
                })
                this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                if (this.transactionSummary) {
                    this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                } else {
                    this.transactionSummary = []
                }
                if (this.enableLazyLoading) {
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async showTransaction() {
            try {
                this.$v.searchValue.$touch()
                if(this.$v.searchValue.$invalid) {
                    return
                }
                this.loaderVisible = true
                let customerEmail = this.searchBy1 === 'senderEmail' ? this.searchValue : null
                let parentReferenceNumber = this.searchBy1 === 'parentReferenceNumber' ? this.searchValue : null
                let referenceNumber = this.searchBy1 === 'referenceNumber' ? this.searchValue : null
                let transactionId =this.searchBy1 === 'transactionId' ? this.searchValue : null
                let transactionDate = null, transactionDateTo = null
                if(this.searchBy2 === 'last30Minutes') {
                    transactionDate =  this.localToUTC(moment(moment(Date.now()).subtract(30, 'Minutes')._d).format('MM-DD-YYYY hh:mm:ss A'))
                    transactionDateTo = this.localToUTC(moment(moment(Date.now())._d).format('MM-DD-YYYY hh:mm:ss A'))
                }else if (this.searchBy2 === 'lastDay') {
                    transactionDate =  this.localToUTC(moment(moment(Date.now()).subtract(1, 'days')._d).format('MM-DD-YYYY hh:mm:ss A'))
                    transactionDateTo = this.localToUTC(moment(moment(Date.now())._d).format('MM-DD-YYYY hh:mm:ss A'))
                }
                let res = await TransactionService.methods.GetTransactionSummary({
                    FundingSource : '2',
                    TransactionStatus : '35',
                    TransactionType : '2',
                    CustomerEmail : customerEmail,
                    ParentReferenceNumber : parentReferenceNumber,
                    ReferenceNumber : referenceNumber,
                    TransactionId : transactionId,
                    TransactionDate : transactionDate,
                    TransactionDateTo : transactionDateTo
                })
                this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                if (this.transactionSummary) {
                    this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                } else {
                    this.transactionSummary = []
                }
                if (this.enableLazyLoading) {
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible=false
            } catch (error) {
                this.loaderVisible=false
            }
        },
        removeUnderscoreText(data) {
            if(data) {
                data=Array.isArray(data) ? data :[data]
                data.forEach(transaction=> Object.keys(transaction).map(key => transaction[key] = transaction[key]?._text))
            }else{
                data=[]
            }
            return data
        },
       async transaction(data,type) {
            try {
                this.$bvModal.msgBoxConfirm('Are you sure you want to ' + type + ' the E-Transafer: '+data.TransactionId)
                .then(async value => {
                    if(value) {
                        this.loaderVisible = true
                        if(type === 'review' || type === 'complete'){
                            this.$router.push({
                                name: 'ReviewMonerisTransaction',
                                params : {
                                    email :data.SenderEmail,
                                    Transactionid :data.TransactionId,
                                    CompleteInteracMonerisParams : {
                                       searchBy1 : this.searchBy1,
                                       searchBy2 : this.searchBy2,
                                       searchValue : this.searchValue
                                    }
                                }
                           })
                        }else if(type === 'reject') {
                            let req = {  
                                CustomerEmail:data.SenderEmail,
                                ReferenceNumber:data.ReferenceNumber
                            }
                            await TransactionService.methods.CancelOpTransaction(req)
                            .then(()=> {
                                this.showAlert(1, 'Transaction Successfully Cancelled')
                                this.transactionSummary = this.transactionSummary.filter(transaction => transaction.ReferenceNumber != data.ReferenceNumber)
                            })
                            .catch(error => {
                                this.alertObject = error
                                this.$bvModal.show(this.$refs.childModal.id)
                            })  
                        }
                    }
                    this.loaderVisible = false
                })
                .catch(error => {
                    this.alertObject = error
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            } catch (error) {
                this.loaderVisible = false
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        localToUTC(datte) {
            return moment(datte, 'MM-DD-YYYY HH:mm:ss A').utc().format("MM/DD/YYYY HH:mm:ss")
        }
    },
    created () {
        if(this.$route.params.CompleteInteracMonerisParams) {
           this.searchBy1 = this.$route.params.CompleteInteracMonerisParams.searchBy1
           this.searchBy2 = this.$route.params.CompleteInteracMonerisParams.searchBy2
           this.searchValue = this.$route.params.CompleteInteracMonerisParams.searchValue
           this.searchValue || (this.searchBy2 === 'last30Minutes'|| this.searchBy2 === 'lastDay') ? this.showTransaction() : this.showAllTransaction()
       }
    },
    computed: {
        transactionSummaryTable() {
            let transactionSummary = []
            if (this.transactionSummary && this.transactionSummary.length > 0) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    transactionSummary = transactionSummary.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                transactionSummary = this.removeUnderscoreText(transactionSummary)
                transactionSummary.forEach(item => item.DateTime = Utils.methods.UTCtoLocal(moment(moment(item.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')))
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return transactionSummary
        }
    },
    validations : {
        searchValue : {
            required: requiredIf(function () {
                return this.searchBy1 != ''
            })
        }
    }
}
</script>